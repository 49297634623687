<template>
  <div>
     <v-row no-gutters class="pa-2">
         <v-col col-md-12>
           <v-card  v-if="!pending.id">
               <v-card-text class="pa-4 subtitle-1" v-if="auth==false">
                 <v-form autocomplete="off">
                  <v-text-field
                   v-model="cashout.bank"
                   style="font-size: 20px; text-align: center;"
                   label="Bank/eWallet Name"
                   hide-details
                   outlined
                   
                 />
                   <v-text-field
                    class="mt-4"
                   v-model="cashout.account_name"
                   style="font-size: 20px; text-align: center;"
                   label="Account Name"
                   hide-details
                   outlined
                   
                 />
                   <v-text-field
                   v-model="cashout.account_number"
                   class="mt-4"
                   style="font-size: 20px; text-align: center;"
                   label="Acct. No. (State you Bank Name/Wallet)"
                   hide-details
                   outlined
                   
                 />
                 <v-text-field
                   v-model="cashout.amount"
                   class="mt-4"
                   style="font-size: 20px; text-align: center;"
                   type="number"
                   label="Credits to Cash Out"
                   
                   outlined
                   
                 />
                 
                 </v-form>
                 <v-divider/>
                 <v-alert
         :border="'top'"
         colored-border
         type="info"
         class="orange--text ma-2"
         elevation="2"
         >
         Important: For amount above 20K it may take 24-48 hours to process. And bank transfer charges may apply!  
         </v-alert>
               </v-card-text>
                 <v-card-text class="pa-4 text-center" v-else>
                         <div class="lg-font mb-3"> For Cashout  <span class="text-h5 text-success">{{$money(text)}}</span> </div>
                         
                   <v-divider/>
                 </v-card-text>
               <v-card-actions>
                       <!-- <v-btn color="warning"  text   @click="text ='close', emitToParent()">close</v-btn> -->
                   <v-spacer></v-spacer>
                   <v-btn large  color="success" :loading="loading" @click="validateAmount()" >Proceed</v-btn>
               </v-card-actions>
           </v-card>
           <template v-else>
             <v-alert
                 text
                 color="info"
               >
                 <h3 class="text-h6">
                   You Have Pending Cashout Request
                 </h3>
                <div>
                   Request Amount: {{$money(pending.amount)}} <br/>
                   Convenience: {{$money(pending.fee)}}<br/>
                   Receivable: {{$money(pending.total)}}<br/>
                   Bank/eWallet: {{pending.bank}}<br/>
                   Account Name: {{pending.account_name}}<br/>
                   Number: {{pending.account_number}}
                </div>
                 <v-divider
                   class="my-4 info"
                   style="opacity: 0.22"
                 ></v-divider>
 
                 <v-row
                   align="center"
                   no-gutters
                 >
                   <p class="font-weight-thin" style="font-size:12px; color: #ffa21a;">
                     <template v-if="pending.remarks == null">
                       We are current processing your request...
                   </template>
                   <template v-else>
                         {{pending.remarks}}
                   </template>
                   </p>
                   <v-spacer/>
                     <v-btn small color="error" @click="cancelCashout()">Cancel</v-btn>
                 </v-row>
               </v-alert>
           </template>
         </v-col>
     </v-row>
     <va-confirm :show="confirm" :text="confirmMsg" :cancelbtn="'Close'" :okbtn="'Confirm'" @DialogEvent="cEvent"/>
 </div>
 </template>
 
 <script>
 import {
   mapMutations
 } from 'vuex'
 export default {
     data: () => ({
         text: '',
         gcash: '',
         gcash_name: '',
         cashout: {},
         action:"request",
         confirmMsg:"",
         confirm: false,
         pending: {id:0},
         sendbtn:"SEND",
          interval: {},
          loading: false,
          value:0,
          auth: false,
     }),
   mounted(){
       // this.setLoggedIn(true)
        this.setDrawer(false)
         if (this.user == null) {
           this.$router.push('/')
         }else {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
           this.getCashout()
         }
     },
   computed:{
     user() {
         if(localStorage.user){
             return JSON.parse(localStorage.user)
         }
         return null
       },
       icon() {
          switch(this.sendbtn) {
            case "SEND":
              return "database-arrow-right"
           case "Points sent":
              return "check"
           default: 
             return ""
          }
       },
       HASPENDING() {
         return this.pending.id
       },
     
   },
   methods: {
     ...mapMutations(['setDrawer']),
     cancelCashout() {
       this.action = "cancel"
       this.confirmMsg = "Would you like to CANCEL this request?" 
       this.confirm = true
     },
     getCashout() {
       this.pending = {id:0}
       this.$http.post("get_cashout", {status: "Pending"}).then(response => {
               if(response.data.status) {
                 if(response.data.pending.id) {
                   this.pending = response.data.pending
                 }
               }
           }).catch(e => {
             console.log(e.data)
           })
     },
     cancel() {
       this.$http.post("cancel_cashout", this.pending).then(response => {
              if(response.data.status) {
                   this.VA_ALERT('success', response.data.message)
                   this.CHECK_BALANCE(this.user.id)
                   this.getCashout()
 
               } else {
                 this.VA_ALERT('error', response.data.message)
               }
              
           }).catch(e => {
             console.log(e.data)
           })
     },
     cEvent(res) {
       if(res == "ok") {
         if(this.action == "Request") {
           this.sendbtn = "Cancel sending"
                 this.loading = true
                 this.Send()
         } else if(this.action == "cancel") {
             this.cancel()
         }
       }
         this.confirm = false
     },
     ValidateSender(){
          if(this.$IsNum(this.text.trim())) {
             this.auth = true
       } else {
         this.VA_ALERT('error', "Enter points!")
       }
     },
     validateAmount(){
       this.action = "Request"
      if(this.cashout.account_number.length < 8) {
       this.VA_ALERT('error', "Please input valid account and bank!")
       return
      }
       if(this.$IsNum(this.cashout.amount.trim())) {
             this.confirmMsg = this.CreateMsg()
             this.confirm = true
       } else {
         this.VA_ALERT('error', "Enter points!")
       }
     },
     
     CreateMsg(){
         return "Cashout " + this.$money(this.$IsNum(this.cashout.amount.trim())) +  " to ("+ this.cashout.bank+")" + this.cashout.account_number
     },
     Send() {
        //  var param = {}
        //  param.gcash = this.gcash.trim()
        //  param.gcash_name = this.gcash_name.trim()
        //  param.type = "GCASH"
        this.cashout.amount = this.$IsNum(this.cashout.amount.trim())
        // console.log(param)
         this.$http.post("new_cashout",this.cashout).then(response => {
               if(response.data.status) {
                   this.VA_ALERT('success', response.data.message)
                   this.gcash = ""
                   this.text = ""
                   this.CHECK_BALANCE(this.user.id)
                   this.getCashout()
               } else {
                   this.VA_ALERT('error', response.data.message)
               }
               this.loading = false
           }).catch(e => {
             console.log(e.data)
           })
 
     },
 
     emitToParent () {
       this.$emit('DialogEvent', this.text.trim())
     }
   }
 }
 </script>
 